

































































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import MainSlogan from "@/components/common/MainSlogan.vue";
import WordOnPic from "@/components/common/WordOnPic.vue";

@Component({
  components: {
    Layout,
    MainSlogan,
    WordOnPic,
  },
})
export default class About extends Vue {}
